import React, { useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { Button } from "@mui/material";
import { CodeDetails } from "../../../shared/types";

export interface BuyButtonProps {
  codeDetails: CodeDetails;
  mobile: boolean;
}

export const BuyButton = ({
  codeDetails,
  mobile,
}: BuyButtonProps): JSX.Element | null => {
  return (
    <Button
      variant="outlined"
      onClick={() => {
        window.location.href = "/shop/";
      }}
      className={codeDetails.valid ? "tb_buy_disabled_button" : "tb_buy_button"}
    >
      {mobile ? "Shop" : "Buy Gift Cards"}
    </Button>
  );
};
