import React from "react"; // eslint-disable-line no-unused-vars
import ReactDOM from "react-dom/client";
import { App } from "./pages";

if (process.env.NODE_ENV === "development") {
  console.log("TB-BUYBUTTON: Loading");
}
let loadApp = function () {
  if (process.env.NODE_ENV === "development") {
    console.log("TB-BUYBUTTON: Initializing React component");
  }
  let apps = document.getElementsByClassName("tbbuybutton");
  for (var i = 0; i < apps.length; i++) {
    let hideOnSpend = apps[i].getAttribute("hideOnSpend");
    let hideOnBuy = apps[i].getAttribute("hideOnBuy");
    const root = ReactDOM.createRoot(apps[i]);
    root.render(
      <App
        hideOnSpend={hideOnSpend == "true"}
        hideOnBuy={hideOnBuy == "true"}
      />
    );
  }
};
if (document.readyState === "loading") {
  if (process.env.NODE_ENV === "development") {
    console.log("TB-BUYBUTTON: Waiting for document ready state");
  }
  document.addEventListener("DOMContentLoaded", loadApp);
} else {
  loadApp();
}
